.news-development {
  & .fast-text {
    font-weight: bold;
    margin-top: 1rem;
  }
  & .divider {
    border-bottom: 1px solid #d3ad1f;
  }
}

.share-social-networks {
  float:right;
  & a {
    background-color: #342107;
    color: #fff;
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
  }
}

.post-image {
  text-align: center;
  & img {
    max-width: 100%;
    height: auto;
  }
}