.important-slider {
  margin-top: -3rem;
  & .slider {
    z-index: 0 !important;
  }
  & .indicators {
    background-color: #f3ca30;
    padding-top: 2rem;
  }
  & .indicators .indicator-item  {
    border-radius: 0;
  }
  & .indicators .indicator-item.active {
    background-color: #342107 !important;
  }
}

.latest-news {
  background-color: #f3ca30;
  padding: 2rem 0;
}

.beer {
  background-color: #fcde6d;
  margin: -0.6rem 0 0 -0.4rem;
  padding: 0.2rem 1rem 0.5rem 1.2rem;
  float: left;
  & svg {
    width: 2.4rem;
  }
}

.section-title, .aside-title {
  border-bottom: 1px solid #d3ad1f;
  text-transform: uppercase;
  font-size: 2rem;
  display: inline-block;
  width: 100%;
  line-height: 4rem;
  margin-bottom: 0;
  & span {
    font-size: 3rem;
    padding: 1rem;
  }
}

.aside-title {
  border-bottom: 1px solid #342107;
  font-size: 1rem;
  line-height: 34px;
  padding-bottom: 0.2rem;
  margin-bottom: 1rem;
  & span {
    font-size: 1.4rem;
    padding: .5rem 0 .5rem .5rem;
  }
  & .beer {
    padding: 0.6rem 0.4rem 0.25rem 0.8rem;
    width: 50px;
    height: 50px;
    & svg {
      width: 1.6rem;
      float: left;
    }
  }
}

.news {
  margin-top: 1rem;
  & h1 {
    border-bottom: 1px solid #d3ad1f;
    margin-bottom: 0.8rem;
    padding-bottom: 0.4rem;
    font-size: 1.2rem;
    & svg{
      height:2rem;
      margin-right: 0.6rem;
    }
  }
  & .cont-img {
    padding: 0;
    & img {
      width: 100%;
      height: auto;
    }
  }
  & .fast-text {
    padding-top: 0.6rem !important;
    background-color: #fcf1ca;
    margin: 0;
    padding: 0.4rem 1.2rem;
  }
  & .btn {
    /*margin: 1rem 15% !important;*/
  }
}

.btn {
  text-transform: initial;
  border-radius: 0;
  background-color: #342107;
  font-size: 1rem;
  line-height: 2.8rem;
  height: 2.8rem;
  margin-top: 1rem;
  & span {
    font-size: 1.2rem;
    font-weight: 700;
  }
  &:hover {
    color: #f3ca30;
    background-color: #342107;
    /*background-color: #fff;*/
    /*text-shadow: 1px 1px 10px #342107;*/
  }
}

.same-height {
  display: flex;
}

.fast-links {
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 0;
  & span {
    position: absolute;
    width: 100%;
    font-size: 1.6rem;
    top: 1.4rem;
  }
  & svg {
    width: 100%;
  }
  & a {
    color: #000;
    padding: 0rem !important;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    position: relative;
  }
}

.associate {
  background-color: #f2e4b1;
}
.begginers {
  background-color: #eed169;
}
.suscribe {
  background-color: #e4aa33;
}

.responsible-consumption {
  padding: 1rem 0;
  /*height: 500px;*/
  text-align: center;
  color:#fff;
  background-image: url('../media/background-consumo-responsable.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  & h1 {
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
    margin:0;
    & span {
      color: #f3ca30;
      font-weight: 900;
    }
  }
  & h2 {
    font-size: 2rem;
    font-weight: 900;
  }
  & svg {
    width: 16rem;
    /*float: right;*/
  }
}


.banner {
  margin-top: 1rem;
  & img {
    width: 100%;
  }
}


@media  (max-device-width : 600px) {
  .same-height {
    display: inherit;
  }
}