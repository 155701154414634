.page-links {
  border-bottom: 1px solid #342107;
  padding-bottom: 2rem;
  & li i {
    background-color: #eed169;
    display: block;
    border-radius: 50%;
  }
  & li a {
    color: #342107;
    display: block;
    /*position: relative;*/
    font-size: 1.2rem;
  }
  & li a svg {
    padding: 16%;
    width: 100%;
  }
  & span {
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0.8rem;
    /*white-space: nowrap;*/
    width: 100%;
    /*position: absolute;*/
    /*bottom: -2rem;*/
  }
  & .active {
    & i {
      background-color: #342107;
      & svg path {
        fill: #eed169;
      }
    }
  }
  & li:hover {
    & i {
      background-color: #342107;
      & svg path {
        fill: #eed169;
      }
    }
  }
}

.static-page {
  & .icon-svg {
    width: 3rem;
    display: inline-block;
    & svg {
      width: 100%;
    }
  }
  & h1 {
    font-size: 4rem;
    font-weight: 900;
    margin: 0.6rem 0;
    & span {
      color: #f3ca30;
      font-weight: 300;
    }
  }
  & h2 {
    font-size: 2rem;
    font-weight: 900;
    margin: 0.6rem 0;
    & span {
      text-transform: uppercase;
      font-weight: 300;
    }
  }
  & ul li {
    position: relative;
    margin-left: 2rem;
  }
  & ul li:before{
    content: "• ";
    color: #eed169;
    font-size: 4rem;
    position: absolute;
    height: 1rem;
    top:-2rem;
    left: -2rem;
  }
  & a {
    color: #342107;
    font-weight: 900;
  }
  & .btn2 {
    background-color: #fcde6d;
    color: #342107;
  }
}

.special-page {
  background: transparent;
  & svg {
    width: 6rem;
  }
}

.authority {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #342107;
  & img {
    width: 80%;
  }
  & h1 {
    border-bottom: 1px solid #eed169;
    padding-bottom: 0.6rem;
    font-size: 1.2rem;
  }
  & h2 {
    color: #eed169;
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    font-size: 1.6rem;
  }
}

.benefit, .organization {
  border: 1px solid #d8d8d8;
  padding: 0 !important;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  & img {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 10%;
    margin-left: 10%;
    width: 80%;
  }
  & div {
    background-color: #fcad00;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  & p {
    border-top: 1px solid #db9a0c;
    border-bottom: 1px solid #db9a0c;
    padding-top: 0;
    padding-bottom: 0;
    margin: 1rem;
    text-transform: uppercase;
  }
  & span {
    display: block;
    font-size: 1.4rem;
    font-weight: 900;
  }
  & .btn {
    margin-bottom: -2rem;
    margin-top: 0rem;
    color: #fff;
  }
}

.more-info {
  display: none;
  & h1 {
    color: #342107;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom:0;
    font-weight: 900;
  }
  & a {
    color: #db9a0c;
  }
}

.good-link1 {
  text-align: center;
  & svg {
    width: 80%;
    margin-left: 10%;
  }
}

.good-link2 {
  text-align: center;
  & svg {
    width: 66%;
    margin-left: 17%;
  }
}

@media  (max-device-width : 860px) {
  .page-links li {
      min-height: 180px;
  }
}
