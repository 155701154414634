main {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
  height: 88px;
}
nav ul a:hover {
  background-color: transparent;
}
header {
  text-align: center;
  overflow: hidden;
  & nav ul svg {
    width: 2.6rem;
    display: block;
    margin:0 auto;
    margin-top: 0.6rem;
    & path {
      fill: #f3ca30;
    }
  }
  & nav {
    position: relative;
    z-index: 2;
    width: 80%;
    margin: 0 auto;
    & .center {
      display: inline-block;
    }
  }
  & nav ul {
    height: 6rem;
    overflow: hidden;
  }
  & nav ul li:nth-child(1) svg {
    margin-bottom: 0.5rem;
  }
  & nav ul li:nth-child(2) svg {
    margin-bottom: 0.7rem;
  }
  & nav ul li:nth-child(3) svg {
    width: 1rem;
  }
  & nav ul li {
    text-align: center;
    line-height: 2rem;
    height: 78px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  & nav ul a {
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0 30px;
  }
  & nav ul li:last-child {
    & a {
      color: #342107;
      background-color: #f3ca30;
      font-size: 1.2rem;
      padding: 0.5rem 0.3rem;
      margin-left: 0.6rem;
      margin-bottom: 1rem;
      &:hover {
        background-color: #fcde6d;
        /*color: #f3ca30;*/
      }
    }
  }
  & video {
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
    /*width: 100%;*/
    height: 350px;
  }
  & .cont-video {
    /*z-index: -200;*/
    height: 350px;
    overflow: hidden;
    margin-bottom: -360px;
    position: relative;
    /*overflow: hidden;*/
  }
}

.button-collapse {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: -1rem;
}

.divider-line {
  width: 100;
  border-top:0.8rem solid #f3ca30;
  border-bottom:0.8rem solid #f3ca30;
  height: 2.6rem;
  display: block;
  background-color: #f3ca30;
  background-image: url('../media/lupulo.svg');
  background-repeat: repeat-x;
}

.top-logo {
  width: 20rem;
  margin: 4rem auto 6rem auto;
  position: relative;
  /*z-index: 10;*/
}
header nav {
  text-transform: uppercase;
  text-align: center;
  background-color: #342107;
  font-size: 0.6rem;
}
header .cont-nav {
  position: relative;
  margin-top: -4rem;
}

header nav:before, header nav:after {
  content: "";
  position: absolute;
  top:0;
  border: 2.8rem solid #342107;
}

header nav:before {
  left: -3rem;
  border-right-width: 0.3rem;
  border-left-color: transparent;
}
header nav:after {
  right: -3rem;
  border-left-width: 0.3rem;
  border-right-color: transparent;
}

.side-nav {

  & a {
    text-align: left;
    width: 100%;
    margin-top: 2rem;
    & svg {
      display: inline-block !important;
      margin-right: 1rem;
    }
  }
  & .social-networks {
    margin-top: 2rem;
    & a {
      background-color: transparent !important;
    }
  }
}
#sidenav-overlay {
  z-index: 1;
}

@media  (max-device-width : 860px) {
  .top-logo {
    width: 60%;
    /*margin: 3rem auto 4rem auto;*/
  }
  header nav:before {
    /*left: -1rem;*/
    display: none;
  }
  header nav:after {
    /*right: -1rem;*/
    display: none;
  }
}

@media  (min-device-width : 1550px) { 

  header video {
    width: 100%;
    height: auto;
  }

}