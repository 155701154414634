html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: #342107;
  color: #fff;
  text-shadow: none;
}

.fonts-loaded body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

body {
  /*font-family: Helvetica, Arial, sans-serif;*/
  font-family: 'Roboto', sans-serif;
  color: #342107;
}

@viewport {
  width: device-width;
}
.container {
  width: 90%;
}

h1, h2, h3, h4, h5 {
  font-size: 1.2rem;
}