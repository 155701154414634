footer {
  background-color: #342107;
  padding: 1rem 0;
  text-align: center;
  color: #fff;
  & small {
    margin-bottom: 1rem;
    display: inline-block;
  }
}

.bottom-logo {
  width: 10%;
  margin: 2rem auto;
}

.social-networks {
  text-align: center;
  margin-bottom: 1rem;
  & a {
    color: #342107;
    background-color: #f3ca30;
    font-size: 1.6rem;
    padding: 0.4rem 0.3rem;
    margin-left: 0.6rem;
    margin-bottom: 1rem;
    &:hover {
      background-color: #fcde6d;
      /*color: #f3ca30;*/
    }
  }
}

@media  (max-device-width : 600px) {
  .bottom-logo {
    width: 30%;
    margin: 2rem auto;
  }
}