aside {
  & a.valign-wrapper {
    display: block;
    text-transform: uppercase;
    color: #000;
    padding: 0.5rem 1.4rem;
  }
  & .valign {
    width: 100%;
  }
  & .associate svg {
    width: 4.6rem;
  }
  & .begginers svg {
    width: 4rem;
  }
  & .suscribe {
    & svg {
      width: 4.6rem;
    }
    & p {
      margin-top: 0.5rem;
    }
    & .link-text {
      margin-top: 1.2rem;
    }
  }
  & .benefits {
    background-color: #bc8513;
    & svg {
      width: 4.6rem;
    }
    & p {
      margin-top: 0;
    }
    & .link-text {
      margin-top: 1.8rem;
    }
  }
  & .link-text {
    float: right;
    font-size: 1.2rem;
    margin-top: 0.8rem;
  }
  & .advertising {
    border: 1px solid #e5e5e5;
    margin-bottom: 1rem;
    display: block;
  }
  & .associate {
    height: 100px;
    }
  & .begginers {
    height: 100px;
  }
  & .suscribe {
    height: 100px;
  }
  & .benefits {
    height: 100px;
  }
}

.shop-widget {
  margin: 1rem 0;
  & img {
    margin: 1rem 0;
  }
}